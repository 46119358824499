.home-container { 
  width: 100%;
  height: 100vh;
  background-image: url("../new-data-services.jpg");
  background-size: auto;
  background-position: center;
  padding: 160px 0;
  display: flex;
  justify-content: center;
}

.text {
line-height: 1em;
max-height: 2em;
}
