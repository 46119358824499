@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,400);
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}


.react-grid-layout {
  background: rgb(255, 255, 255);
}
.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -webkit-columns: 120px;
  columns: 120px;
}
.react-grid-item {
  box-sizing: border-box;
}
.react-grid-item:not(.react-grid-placeholder) {
  /*background: #ccc;*/
  border: 1px solid black;
}
.react-grid-item.resizing {
  opacity: 0.9;
}
.react-grid-item.static {
  background: #cce;
}
.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}

.react-grid-item .minMax {
  font-size: 12px;
}
.react-grid-item .add {
  cursor: pointer;
}
.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
}

.toolbox {
  background-color: #dfd;
  width: 100%;
  height: 120px;
  overflow: scroll;
}

.hide-button {
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  top: 0px;
  right: 5px;
}

.toolbox__title {
  font-size: 24px;
  margin-bottom: 5px;
}
.toolbox__items {
  display: block;
}
.toolbox__items__item {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: 5px;
  border: 1px solid black;
  background-color: #ddd;
}
.droppable-element {
  width: 150px;
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}
/*original en adelante*/

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
.spinner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  line-height: 1em;
  max-height: 2em;
}

.docscontent {
  padding-top: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.react-grid-layout .layout {
  padding-top: 10px;
}

.login-container {
  padding: 160px 0;
}

.google-btn {
  margin: 0 auto;
  width: 184px;
  height: 42px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: white;
}
.google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: black;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
  background: white;
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.google-btn:active {
  background: white;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 9999;
  padding-top: 20px;
}

.tooltip {
  position: absolute;
  color: #fff;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  transform: translate3d(-50%, -50%, 0);
  border-radius: 5px;
  pointer-events: none;
  z-index: 1000;
}

.home-container { 
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/new-data-services.2c5dab4f.jpg);
  background-size: auto;
  background-position: center;
  padding: 160px 0;
  display: flex;
  justify-content: center;
}

.text {
line-height: 1em;
max-height: 2em;
}

.spinner {
  height: 100vh;
  display: flex; 
  align-items: center; 
  justify-content: center;
}

.custom {
  padding-top: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.custom2 {
  width: 80%;
}
.spinner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reportes {
  padding-top: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.procesost {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  height:80vh;
}
.procesost table {
  table-layout: auto !important;
}

.procesost .table th,
.procesost .table td {
  white-space: nowrap;
}

.procesost thead tr:nth-child(1) th{
  background: white; 
  position: -webkit-sticky; 
  position: sticky;
  top: 0;
  z-index: 10;
}

/*
.react-bootstrap-table table {
  table-layout: auto;
  margin-top: 15px;

}

.procesost tbody {
  display:block;
  height:70vh;
  overflow-y:scroll;
  }
  .procesost thead{
  display:table;
  width:100%;
  table-layout:fixed;
  }
  .procesost tbody tr {
  display:table;
  width:100%;
  table-layout:fixed;
  }
  */
.NotFound {
  padding-top: 100px;
  text-align: center;
}

.spinner {
  height: 100vh;
  display: flex; 
  align-items: center; 
  justify-content: center;
}

*.content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

html {
  margin: 0;
  width: 100%;
 }
 
 body {
   margin: 0;
   padding: 0;
   font-family: sans-serif;
   margin: 0 auto;
   height: 100vh;
   width: 100%;
   /*overflow-y:hidden;*/
 }
 
 body::-webkit-scrollbar {
  display: none;
 }

 .login-button {
  height: 100px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background: black;
  margin: 0 auto;
 }
 .login-container { 
  /*background: #FFC107;*/
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/workplace.119938d0.jpg);
  background-size: auto;
  background-position: center;
 }
 .button-text {
  position: relative;
  font-size: 25px;
  color: white;
  line-height: 50px;
  left: 90px;
  cursor: pointer;
 }
 .google-logo {
  height: 50px;
  float: left;
  background-size: contain;
  background-repeat: no-repeat; 
  margin: 25px 20px;
 }
 #root {
   width: 100%;
   height: 100vh;
   margin: 0 auto;
   margin-bottom: 50px;
 }
  
 .login-h1{
   font-size: 82px;
   line-height: 6.1;
   color: black;
 }
 
 .App {
   text-align: center;
 }
 
 .scrolling-wrapper {
   overflow-x: scroll;
   overflow-y: hidden;
   white-space: nowrap;
 }
 
 .scrolling-wrapper .main-photo.card-1.card {
     display: inline-block !important;
     color: white;
     text-align: center;
     padding: 14px;
     text-decoration: none;
 }
 
 .card-1.card.loading-card {
   padding-top: 30px !important;
 }
 
 
 .App-logo {
   -webkit-animation: App-logo-spin infinite 20s linear;
           animation: App-logo-spin infinite 20s linear;
   height: 80px;
 }
  
 .testimonial-group > .row {
   overflow-x: auto;
   white-space: nowrap;
 }
 .testimonial-group > .row > .col-xs-4 {
   display: inline-block;
   float: none;
 }
 
 .main-photo {
   background-position: center center;
   background-size: cover;
   background-repeat: no-repeat;
   height: 100px;
   width: 100px;
   margin: 20px;
   border-radius: 50%;
 }
 
 .main-close {
   position: relative;
   top: 50px;
   left: 10px;
 }
 
 .card-1 {
   box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
   transition: all 0.3s cubic-bezier(.25,.8,.25,1);
   padding: 25px;
   padding-top: 30px;
 }
 
 .card-1:hover {
   box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
 }
 
 .dopple-image {
   height: 100px;
   width: 100px;
   border-radius: 50%;
 }
 
 
 .text-center {
   height: 130px;
   overflow-y: hidden;
   margin-bottom: 20px;
 }
 
 .App-header {
   background-color: #222;
   height: 150px;
   padding: 20px;
   color: white;
 }
 
 .App-intro {
   font-size: large;
 }
 
 @-webkit-keyframes App-logo-spin {
   from { transform: rotate(0deg); }
   to { transform: rotate(360deg); }
 }
 
 @keyframes App-logo-spin {
   from { transform: rotate(0deg); }
   to { transform: rotate(360deg); }
 }
 
 .style{
   height: 100px;
 }
 
 .google-login {
   line-height:6.5;
   width: 100%;
   min-height: 100px;
   position: fixed;
   bottom: 0;
 }
 
 .bottom-nav {
   height: 50px;
   width: 100%;
   margin-left: 0;
   margin-right: 0;
   position: fixed;
   bottom: 0;
   color: white;
   background-color: black;
 }
 .col-bottom {
   text-align: center;
 }
 
 .col-bottom a {
   color: white;
 }
 
 .col-bottom :hover {
   color: rgb(108,248,1880);
 }
 
 .bottom-icon {
   font-size: 35px;
   line-height: 44px;
 }
