.spinner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reportes {
  padding-top: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.procesost {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  height:80vh;
}
.procesost table {
  table-layout: auto !important;
}

.procesost .table th,
.procesost .table td {
  white-space: nowrap;
}

.procesost thead tr:nth-child(1) th{
  background: white; 
  position: sticky;
  top: 0;
  z-index: 10;
}

/*
.react-bootstrap-table table {
  table-layout: auto;
  margin-top: 15px;

}

.procesost tbody {
  display:block;
  height:70vh;
  overflow-y:scroll;
  }
  .procesost thead{
  display:table;
  width:100%;
  table-layout:fixed;
  }
  .procesost tbody tr {
  display:table;
  width:100%;
  table-layout:fixed;
  }
  */