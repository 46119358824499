.spinner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  line-height: 1em;
  max-height: 2em;
}

.docscontent {
  padding-top: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.react-grid-layout .layout {
  padding-top: 10px;
}