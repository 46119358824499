
.login-container {
  padding: 160px 0;
}

.google-btn {
  margin: 0 auto;
  width: 184px;
  height: 42px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: white;
}
.google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: black;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
  background: white;
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.google-btn:active {
  background: white;
}

@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,400);
