.spinner {
  height: 100vh;
  display: flex; 
  align-items: center; 
  justify-content: center;
}

.custom {
  padding-top: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.custom2 {
  width: 80%;
}